<!--
 * @Author: lbh
 * @Date: 2024-08-22 14:57:39
 * @LastEditors: lbh
 * @LastEditTime: 2024-09-10 15:03:57
 * @Description: file content
-->
<template>
  <div
    class="cell-swiper px-cell-swiper"
    @click="clickItem('title')"
    :class="{ hasTop: configs.showTopImg }"
    :style="{
      '--background-color': configs.bgColor,
      '--background-image': `url('${configs.bg}')`,
    }"
  >
    <div class="top-radius">
      <img
        v-if="configs.showTopImg"
        src="https://hk-ricepon.oss-cn-hongkong.aliyuncs.com//images/webAdmin//1709627999552png"
      />
    </div>
    <div class="body-center">
      <div
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></div>
      <div
        class="desc"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      ></div>
      <div
        v-for="(item, index) in columnList"
        :key="index"
        class="cell"
        :style="{ '--num': maxNum }"
      >
        <div class="boxes" :class="`boxes_${index}`">
          <div class="box" v-for="(nItem, nIndex) in item" :key="nIndex">
            <img :src="nItem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
import langMixin from '../../mixins/lang';

export default {
  name: 'cell-swiper',
  mixins: [langMixin],
  props: {
    configs: {
      default() {
        return {};
      },
    },
    Xindex: {
      default() {
        return '';
      },
    },
  },
  watch: {
    'configs.column'() {
      this.initData();
    },
    'configs.items.length'() {
      this.initData();
    },
  },
  data() {
    return {
      columnList: [],
      maxNum: 0,
      animeList: [],
    };
  },
  mounted() {
    let ww = window.innerWidth;
    this.maxNum = Math.floor(ww / 170);
    this.initData();
  },
  computed: {
    isEdit() {
      return this.$store.getters.getIsEdit;
    },
  },
  methods: {
    initData() {
      let { items: dataList, column } = this.configs;
      let list = this.distributeNumbers(dataList, column);
      console.log(list);
      this.columnList = list;
      this.$nextTick(() => {
        for (let i in list) {
          this.initSwiper(list[i].length, i);
        }
      });
    },
    initSwiper(n = 8, i_) {
      let xTrans = [];
      let w = 150;
      let b = 20;
      if (window.innerWidth < 600) {
        w = 100;
        b = 10;
      }
      if (this.animeList[i_]) {
        this.animeList[i_].anime.remove(this.animeList[i_].xTrans);
      }
      let class_ = `.boxes_${i_} .box`;
      anime.set(class_, {
        translateX: function (el, i, l) {
          xTrans[i] = { x: i * (w + b) };
          return i * (w + b);
        },
      });
      let animeDom = anime({
        targets: xTrans,
        duration: i_ % 2 == 1 ? (n - 1) * 1.5 * 1000 : (n - 1) * 1000,
        easing: 'linear',
        x: `+=${(w + b) * n}`,
        loop: true,
        update: function (anim) {
          anime.set(class_, {
            translateX: function (el, i, l) {
              return xTrans[i].x % ((w + b) * n);
            },
          });
        },
      });

      this.animeList[i_] = {
        anime: animeDom,
        xTrans: xTrans,
      };
      window.animeList = this.animeList;
    },

    distributeNumbers(list, column) {
      let list_ = this.$util.object.deepClone(list);
      // * 计算每个元素应该有的平均值（向上取整）
      let num = list_.length;
      // * 向上取整
      let ceil = Math.ceil(num / column);
      // * 向下取整
      let floor = Math.floor(num / column);
      let result = [];
      // * 理論上的平均值
      let n = floor * column;
      for (let i = 0; i < column; i++) {
        if (n + 1 > num) {
          result.push(list_.splice(0, floor));
        } else {
          result.push(list_.splice(0, ceil));
          n += 1;
        }
      }
      return result;
    },

    clickItem(e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cell-swiper {
  &.hasTop {
    margin-top: calc(var(--font80) * -1);
    z-index: 10;
    position: relative;
    .body-center {
      padding: 0 !important;
    }
  }
  .body-center {
    padding: var(--font80) 0;
  }
  .top-radius {
    width: 100%;
    transform: translateY(var(--font2));
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
  .title {
    font-size: 44px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    // color: #ffffff;
    text-align: center;
  }
  .desc {
    font-size: 26px;
    font-family: 'Microsoft Yahei';
    font-weight: 200;
    line-height: 40px;
    text-align: center;
    // color: #ffffff;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.px-cell-swiper {
  --w: 150px;
  .body-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--background-color, #fff);
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      background-image: linear-gradient(
        to right,
        rgba(242, 248, 255, 1),
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0) 70%,
        rgba(242, 248, 255, 1)
      );
    }

    .cell {
      width: calc((var(--num)) * var(--w));

      overflow: hidden;
      .boxes {
        width: calc((var(--num) + 1) * var(--w));
        overflow: hidden;
        position: relative;
        height: calc(var(--w) / 1.85);
        padding: 10px 0;
        left: calc(var(--w) * -1);
        .box {
          width: var(--w);
          min-width: var(--w);
          height: calc(var(--w) / 1.85);
          // height: auto;
          position: absolute;
          box-shadow: -6px 6px 8px rgba(194, 226, 252, 0.4),
            6px -6px 8px rgba(194, 226, 252, 0.4);
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 8px;
          justify-content: center;
          img {
            max-width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-cell-swiper {
    // padding: 20px 0 80px 0;
    .title {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
    }
    .desc {
      margin-top: 4px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      padding: 0 54px;
      text-align: center;
      padding: 0 50px;
    }
  }
}

@media screen and(max-width:600px) {
  .px-cell-swiper {
    --w: 100px;
    // padding: 20px 0 80px;
    .title {
      font-size: 20px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
    }
    .desc {
      margin-top: 4px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      padding: 0 54px;
      text-align: center;
      line-height: 20px;
    }
    .body-center {
      &::before {
        display: none;
      }
      .cell {
        height: auto;
        padding: 0;
        width: 100%;
        .boxes {
          width: calc(100% + 120px);
          left: -120px;
        }
      }
    }
  }
}
</style>
